import * as React from "react";
import { render } from "react-dom";

class AmandasApp extends React.Component {
  constructor() {
    super();
    this.incrementLeft = this.incrementLeft.bind(this);
    this.incrementRight = this.incrementRight.bind(this);
    this.updateDraft = this.updateDraft.bind(this);
    this.updateDraftRight = this.updateDraftRight.bind(this);
    this.addTodo = this.addTodo.bind(this);
    this.addTodoRight = this.addTodoRight.bind(this);
    this.state = {
      times: 3000,
      draftItem: "",
      draftItem2: "",
      todoList: ["love amanda", "make her coffee", "make her a code slinger"],
      todoList2: [
        "Feed him",
        "give him back scratches",
        "bring him water",
        "love him forever",
      ],
      times2: 3000,
    };
  }

  incrementLeft() {
    this.setState({
      times2: this.state.times2 + 1,
      times: this.state.times - 1,
    });
  }

  incrementRight() {
    this.setState({
      times: this.state.times + 1,
      times2: this.state.times2 - 1,
    });
  }

  getTodoItems(todoList) {
    var items = [];
    for (let i = 0; i < todoList.length; i++) {
      var item = todoList[i];
      items.push(<li>{item}</li>);
    }
    return <ol>{items}</ol>;
  }

  updateDraft(event) {
    this.setState({ draftItem: event.target.value });
  }
  updateDraftRight(event) {
    this.setState({ draftItem2: event.target.value });
  }
  addTodo() {
    if (this.state.draftItem.trim() === "") {
      return;
    }
    this.setState({
      todoList: [...this.state.todoList, this.state.draftItem],
      draftItem: "",
    });
  }
  addTodoRight() {
    if (this.state.draftItem2?.trim() === "") {
      return;
    }
    this.setState({
      todoList2: [...this.state.todoList2, this.state.draftItem2],
      draftItem2: "",
    });
  }

  render() {
    return (
      <div id="container">
        <div id="box-1">
          <p>Hello world! </p>
          <p>This is the Panda Playground! 🐼</p>
          <p>i love amanda x{this.state.times}! ❤️</p>
          <a href="#" onClick={this.incrementLeft}>
            moaaarr loveee!!!
          </a>
          <br />
          <input
            type="text"
            placeholder="send love!"
            value={this.state.draftItem}
            onChange={this.updateDraft}
          />
          <button onClick={this.addTodo}>add todo</button>
          <br />

          {this.getTodoItems(this.state.todoList)}
        </div>
        <div id="box-2">
          <p>Hiiii cutie pie!!</p>
          <p>To the cutest boyfriend ever 🦔</p>
          <p>i love nick x{this.state.times2}! ❤️</p>
          <a href="#" onClick={this.incrementRight}>
            I love you mooaarr!!
          </a>
          <br />
          <input
            type="text"
            placeholder="kisses and hugs"
            value={this.state.draftItem2}
            onChange={this.updateDraftRight}
          />
          <button onClick={this.addTodoRight}>add todo</button>
          <br />
          {this.getTodoItems(this.state.todoList2)}
        </div>
      </div>
    );
  }
}

render(<AmandasApp />, document.getElementById("root"));
